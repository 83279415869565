module.exports={
    author:"vLite",
    title:'vLite',
    icon:require("@/assets/icons/logo/vLite/favicon.png"),
    assetpath:'vlite',
    config_path:["https://vlite-config-72b7a-default-rtdb.firebaseio.com/vlite-gogo/v1.json","https://vlite-config.firebaseio.com/preprod/web/vlite-gogo/v1.json"],
    _fbId: "550400871992321",
    _googleId: "252896885440-kut4hnevv3u8adomdmfqfqgqq7csddfh.apps.googleusercontent.com",
    pwaData:{
        title:"vLite",
        maskColor:"#30d158",
        msColor:"#da532c",
        themeColor:"#ffffff"
      },
    playerConfig:{
        color: "#FF9F0A",
        logo:require("@/assets/icons/logo/vLite/watermark.svg"),
        fingerprint:true
    },
    pushConfiguration:[
        ['messagingId','458080364267'],
        ['vendorName','vLite']
    ],
    vendorAssets:'vLite',
    bodyID:"vlite",
    firebase:{
      primary:{
        apiKey: "AIzaSyAPt1xpDlRTJuh3eo31_26E1RFOmlQz7DE",
        authDomain: "vlite-preprod.firebaseapp.com",
        databaseURL: "https://vlite-preprod.firebaseio.com",
        projectId: "vlite-preprod",
        storageBucket: "vlite-preprod.appspot.com",
        messagingSenderId: "252896885440",
        appId: "1:252896885440:web:4fb95e1c15fc244fd8cb25"
      },
      secondary:{
          apiKey: "AIzaSyBY3QKD3XB_rkQUgtn6f8CRdqBIZaXY0Fs",
          authDomain: "vlite-demo-ent1.firebaseapp.com",
          databaseURL: "https://vlite-demo-ent1.firebaseio.com",
          projectId: "vlite-demo-ent1",
          storageBucket: "vlite-demo-ent1.appspot.com",
          messagingSenderId: "458080364267",
          appId: "1:458080364267:web:b7bbccbe481c0251d940ae",
          measurementId: "G-X6F7SMGTTE"
      },
      serverKey: 'AAAAaqe3pus:APA91bGZPGyXdM5NoDh7lMDgg6GPKEUr6ycjUr5Wv2_XaD4OPsr3jknS3HJJmZj2IfjqIOGOIrZGMhMKnTSeT_dAMsmrEx9ocuYLp_jflFfr26Q6DOacDcWdrRcoSHoYZ_uD73WIG6Yv',
      certificateKey: 'BITbhEam3c8wjjCTtm8lR8K7EfPnWjSOmERNO9oBZ3kgIjI302119PGRuwmkJd1hD1yTiExOKxAli4vpD0AEFgQ'
    }
  }